<template>
  <div class="church-presentation-template">
    <page-body class>
      <section class="section-1 bg-block mb-4">
        <div class="bg-block p-4">
          <h4>{{ individualProfile.formal_name }}</h4>
          <h1 class="sm mb-4">{{ translations.tcEditPreferredLanguage }}</h1>
          <div class="body">
            <b-form>
              <b-form-group class="form-element">
                <div class="g-row">
                  <b-row>
                    <b-col sm="6">
                      <b-form-group class="form-element form-lang-container">
                        <h3 class="flex-2 mr-3 text-left font-style-2">
                          {{ translations.tcPreferredLanguage }}
                        </h3>
                        <b-form-select v-model="selectedLanguage" :options="optionsLanguagesTranslated"
                          class="form-control g-select flex-0 mr-3 mb-3">
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </b-form-group>
            </b-form>
          </div>
          <div class="body">
            <div class="d-flex flex-sm-wrap btn-action-container">
              <b-button @click="handleSaveClick" variant="primary" class="flex-1 mr-3 w-100-sd mb-3 mb-sm-0">{{
                translations.tcSave
              }}</b-button>
              <b-button @click="handleCancelClick" variant="tertiary" class="flex-1 w-100-sd mb-3 mb-sm-0">
                {{ translations.tcCancel }}
              </b-button>
            </div>
          </div>
        </div>
      </section>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import breadcrumbService from '@/services/breadcrumbService'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'edit-preferred-language',
  mixins: [translationMixin],
  data() {
    return {
      defaultPreferredCulture: 'en',
      defaultPreferredLanguage: 'English',
      english: 'c3964f7d-1307-48b9-9f60-dae9388b5478',
      optionsLanguagesTranslated: [],
      selectedLanguage: {},
      translations: {},
    }
  },
  methods: {
    ...mapActions({
      getIndividualCommPreferences: 'membership/getIndividualCommPreferences',
      loadIndividualProfile: 'membership/loadIndividualProfile',
      loadPreferredLanguages: 'membership/loadPreferredLanguages',
      savePreferredLanguage: 'membership/savePreferredLanguage',
      setLoadingStatus: 'menu/setLoadingStatus',
      setPreferredCulture: 'user/setPreferredCulture',
      setPreferredLanguage: 'user/setPreferredLanguage',
      translateMenu: 'translation/fetchMenuTranslations',
    }),
    async pageLoad() {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.loadPreferredLanguages(),
        this.getComponentTranslations('freshdesk-widget'),
        this.getComponentTranslations('common.church-languages')
      ]).then(results => {
        this.setLanguageTranslations(results)
        this.translateLanguageDropdown()
        this.selectedLanguage = {
          lnt_key: this.individualCommunicationPreferencesData.preferredLanguage.lnt_key || this.english,
          lnt_culture:
            this.individualCommunicationPreferencesData.preferredLanguage.lnt_culture || this.defaultPreferredCulture,
          lnt_language:
            this.individualCommunicationPreferencesData.preferredLanguage.lnt_language ||
            this.defaultPreferredLanguage
        }
        this.setLoadingStatus(false)
      }
      )
    },
    async handleSaveClick() {
      if (this.selectedLanguage === null) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorLanguageRequired,
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then((result) => {
          return
        })
      } else {
        var payload = {
          mpl_key: this.individualCommunicationPreferencesData.preferredLanguage.mpl_key,
          mpl_ind_key: this.individualProfile.ind_key,
          mpl_lnt_key: this.selectedLanguage.lnt_key,
          member_no: this.demograph.member_number,
          delete_flag: false,
        }

        let result = true
        let promise = null
        let isEditingOwnProfile = this.userIndKey === this.individualProfile.ind_key

        if (isEditingOwnProfile) {
          promise = Promise.all([
            this.setLoadingStatus(true),
            (result = await this.savePreferredLanguage(payload)),
            this.getIndividualCommPreferences(this.userSelectedIndividualKey),
            this.setPreferredCulture(this.selectedLanguage.lnt_culture),
            this.setPreferredLanguage(this.selectedLanguage.lnt_language),
            this.translateMenu(this.selectedLanguage.lnt_culture),
          ])
        } else {
          promise = Promise.all([
            this.setLoadingStatus(true),
            (result = await this.savePreferredLanguage(payload)),
            this.getIndividualCommPreferences(this.userSelectedIndividualKey),
          ])
        }

        this.translations = {}  // new language selected, now re-fetch translations for modal message
        await Promise.all([
          breadcrumbService.loadBreadcrumTranslations(),  //Translate breadcrumb when `prefCulture` is changed
          this.getViewTranslations(),
          this.getComponentTranslations('freshdesk-widget'),
          this.getComponentTranslations('common.church-languages'),
        ]).then(results => {
          this.$emit('langChange', true)  // tell App.vue to get header translations again
          this.setLanguageTranslations(results)
          this.translateLanguageDropdown()
          return true
        })

        await promise.then(() => {
          this.setLoadingStatus(false)
          this.$swal({
            icon: result ? 'success' : 'error',
            text: result ? this.translations.tcLanguageSaved : this.translations.tcErrorLanguageNotSaved,
            confirmButtonText: this.translations.tcOk || 'Ok',
          }).then((result) => {
            if (result.value) {
              this.handleCancelClick()
            }
          })
        })
      }
    },
    async emailStoreAndRedirect(data) {
      this.setSelectedEmail(data)
      this.$router.push({ name: 'add-member-email' })
    },
    async handleCancelClick() {
      this.$router.push({ name: 'user-preferences' })
    },
    setLanguageTranslations(givenResults) {
      this.stripReadableText(givenResults[3], 'guid')
      const translatedText = {
        ...givenResults[2],
        common: { ...this.translations.common }
      }
      return this.$set(this.translations, 'components', translatedText)
    },
    async translateLanguageDropdown() {
      return this.translateDropdownOptions([{
        node: 'church-languages',
        store: 'optionsLanguages',
        disabledPlaceholder: this.translations.tcSelectLanguage,
        overrideItemKey: 'lnt_key'
      }])
    },
  },
  computed: {
    ...mapGetters({
      demograph: 'user/demograph',
      individualCommunicationPreferencesData: 'membership/individualCommunicationPreferencesData',
      individualProfile: 'membership/individualProfile',
      prefCulture: 'user/userPreferredCulture',
      preferredLanguages: 'membership/preferredLanguages',
      userIndKey: 'user/userId',
      userName: 'user/userName',
      userSelectedEmail: 'user/userSelectedEmail',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey',
    }),
    optionsLanguages() {
      if (this.preferredLanguages.length === 0) {
        return []
      }

      return [
        ...this.preferredLanguages.map((lng) => {
          return {
            text: lng.name,
            value: { lnt_key: lng.lnt_key, lnt_culture: lng.culture_code, lnt_language: lng.name },
          }
        }),
      ]
    },
  },
  async created() {
    if (!this.userSelectedIndividualKey) {
      this.$router.push('/programs/mb/membership/')
    }
    await this.pageLoad()
  },
  components: {
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';

div.btn-action-container {
  flex-wrap: nowrap;
  justify-content: flex-start;

  @include breakpoint(mobile) {
    justify-content: center;
    flex-wrap: wrap;
  }
}

div.btn-action-container>button {
  flex-grow: 0 !important;

  @include breakpoint(mobile) {
    margin-right: 0 !important;
  }
}

.form-lang-container {
  min-width: 400px;

  @include breakpoint(mobile) {
    min-width: 300px;
  }
}

.page-body {
  padding: 80px 15px;
}
</style>
